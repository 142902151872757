import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./Pages/home/home.module').then( m => m.HomePageModule)},
  {
    path: 'login',
    loadChildren: () => import('./Pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./Pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./Pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./Pages/landing-page/landing-page.module').then( m => m.LandingPagePageModule)
  },
  {
    path: 'signup-verification',
    loadChildren: () => import('./Pages/signup-verification/signup-verification.module').then( m => m.SignupVerificationPageModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('./Pages/requests/requests.module').then( m => m.RequestsPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./Pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'giving',
    loadChildren: () => import('./Pages/giving/giving.module').then( m => m.GivingPageModule)
  },
  {
    path: 'my-requests',
    loadChildren: () => import('./Pages/my-requests/my-requests.module').then( m => m.MyRequestsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
